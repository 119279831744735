import Image from "next/image";
import lightCoin from "@/assets/icons/coin.png";
import darkCoin from "@/assets/icons/dark_coin.png";
import pearplCoin from "@/assets/icons/pearpl_coin.png";


type AutoBoostersCounterType = {
  boosters: number;
  superBoosterActive: boolean | undefined;
};

import styles from '../styles.module.scss'

export const AutoBoostersCounter = ({
  boosters,
  superBoosterActive,
}: AutoBoostersCounterType) => {
  return (
    <>
      {boosters > 0 && (
        <div className={styles.coins}>
          {superBoosterActive ? (
            <>
              {boosters >= 1 && (
                <Image
                  src={pearplCoin}
                  alt="Super Booster Coin"
                  quality={100}
                  width={16}
                  className={styles.coin}
                />
              )}
              {boosters >= 2 && (
                <>
                  <Image
                    src={lightCoin}
                    alt="Coin 1"
                    quality={100}
                    width={16}
                    className={styles.coin}
                  />
                  <Image
                    src={boosters >= 3 ? lightCoin : darkCoin}
                    alt="Coin 2"
                    quality={100}
                    width={16}
                    className={styles.coin}
                  />
                </>
              )}
            </>
          ) : (
            <>
              <Image
                src={boosters >= 1 ? lightCoin : darkCoin}
                alt="Coin 1"
                quality={100}
                width={16}
                className={styles.coin}
              />
              <Image
                src={boosters >= 2 ? lightCoin : darkCoin}
                alt="Coin 2"
                quality={100}
                width={16}
                className={styles.coin}
              />
            </>
          )}
        </div>
      )}
    </>
  );
};

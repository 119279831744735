import { useEffect, useRef, useState } from "react";
import {
  Application,
  Container,
  Texture,
  AnimatedSprite,
  Assets,
} from "pixi.js";
import { ISpriteProps } from "./interfaces";
import { SPEED } from "./sprite.constants";

import styles from "./styles.module.scss";

const SpriteComponent: React.FC<ISpriteProps> = ({ options, onEnd }) => {
  const { width, height, speed = 1, directory, position } = options;
  const [isPlaying, setIsPlaying] = useState(false);
  const appRef = useRef<Application | null>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const spriteRef = useRef<AnimatedSprite | null>(null);
  const [sprites, setSprites] = useState<Texture[]>([]);
  const COIN = "gathering_coin";

  const setupAnim = async () => {
    
    const multiplier = directory == COIN ? 2 : 1;
    const asset = Assets.get(directory);
    if (asset && asset.animations[directory]) {
      const textures = asset.animations[directory];
      setSprites(textures);
      setIsPlaying(true);
    }

    if (!appRef.current) {
      appRef.current = new Application();

      await appRef.current.init({
        width: width,
        height: height * multiplier,
        backgroundAlpha: 0,
        roundPixels: true,
        resolution: window.devicePixelRatio || 1,
        autoDensity: true,
      });

      if (containerRef.current) {
        containerRef.current.appendChild(appRef.current.canvas);
      }
    }

    const container = new Container();
    appRef.current.stage.addChild(container);

    if (sprites.length <= 0) return;
    const animatedSprite = new AnimatedSprite(sprites);

    animatedSprite.position.set(position.x, position.y);
    animatedSprite.animationSpeed = SPEED * speed;
    animatedSprite.loop = false;
    animatedSprite.width = width;
    animatedSprite.height = height;

    animatedSprite.onComplete = () => {
      setIsPlaying(false);
      if (onEnd) onEnd();
    };

    spriteRef.current = animatedSprite;
    container.addChild(animatedSprite);

    animatedSprite.play();
  };

  useEffect(() => {
    setupAnim();

    return () => {
      setIsPlaying(false);
    };
  }, [width, height, speed, sprites]);

  return (
    <>
      {isPlaying && (
        <div
          ref={containerRef}
          className={styles.wrapper}
          style={{
            width: width,
            height: height,
            zIndex: 20,
            overflow: "visible",
          }}
        />
      )}
    </>
  );
};

export default SpriteComponent;

import { Application } from "pixi.js";
import { useCallback, useEffect, useRef } from "react";
import { Spine } from "@pixi/spine-pixi";
import { AnimationsName } from "@/helpers";
import { usePreloadingContext } from "@/context/usePreloading";

import styles from "./styles.module.scss";

type ShineCanvasProps = {
  className?: string;
  width?: number;
  height?: number;
  scale?:number;
};

export const ShineCanvas = ({ className, width, height,scale }: ShineCanvasProps) => {
  const appRef = useRef<Application | null>(null);
  const canvasRef = useRef<HTMLDivElement | null>(null);
  const shineRef = useRef<Spine | null>(null);
  const { atlasArray, skeletonArray, isPreloadingFinish } =
    usePreloadingContext();
  const setup = useCallback(async () => {
    if (canvasRef.current && typeof window !== "undefined") {
      appRef.current = new Application();
      await appRef.current.init({
        width: width || 150,
        height: height || 150,
        backgroundAlpha: 0,
        backgroundColor: "rgba(0,0,0,0)",
        roundPixels: true,
        resolution: window.devicePixelRatio || 1,
        autoDensity: true,
      });
      canvasRef.current.appendChild(appRef.current.canvas);
    }
  }, []);

  const setupAnim = useCallback(async () => {
    const app = appRef.current;

    const skeletonItem = skeletonArray.find((item) =>
      item.alias.includes(AnimationsName.STARS_SKELETON)
    );
    const atlasItem = atlasArray.find((item) =>
      item.alias.includes(AnimationsName.STARS_ATLAS)
    );

    if (!skeletonItem || !atlasItem) {
      console.warn(
        `No skeleton or atlas found for gender ${AnimationsName.STARS}`
      );
      return;
    }

    const skeletonAlias = skeletonItem.alias;
    const atlasAlias = atlasItem.alias;

    if (!skeletonAlias || !atlasAlias) return;

    const spine = Spine.from({
      skeleton: skeletonAlias,
      atlas: atlasAlias,
      scale:scale|| 0.16,
    });

    shineRef.current = spine;

    shineRef.current.skeleton.setSlotsToSetupPose();

    shineRef.current.visible = false;
    app?.stage?.addChild(shineRef.current);

    if (app?.renderer) {
      shineRef.current.x = app.renderer.width / 1.8;
      shineRef.current.y = app.renderer.height / 1.3;
    }

    const animationState = shineRef.current.state;
    animationState.timeScale = 1.5;
    animationState.setAnimation(0, AnimationsName.STARS, true);

    requestAnimationFrame(() => {
      if (shineRef.current) {
        shineRef.current.visible = true;
      }
    });
  }, []);

  const init = useCallback(async () => {
    if (!appRef.current) await setup();

    if (shineRef.current) {
      appRef.current?.stage.removeChild(shineRef.current);
      shineRef.current.destroy();
      shineRef.current = null;
    }

    await setupAnim();
  }, [setup, setupAnim]);

  useEffect(() => {
    if (isPreloadingFinish) init();
    return () => {
      if (shineRef.current) {
        appRef.current?.stage.removeChild(shineRef.current);
        shineRef.current.destroy({ children: true });
        shineRef.current = null;
      }
      if (appRef.current) {
        appRef.current.destroy(true, { children: true });
        appRef.current = null;
      }
    };
  }, [init, isPreloadingFinish]);

  return <div className={styles.shine} ref={canvasRef} />;
};

export type AssetType = {
  alias: string;
  src: string;
};
export enum AnimationsName {
  FANFARES_ATLAS = "fanfares_atlas",
  FANFARES_SKELETON = "fanfares",
  CHEST_SKELETON = "chest",
  STARS_SKELETON = "stars",
  FANFARES = "action",
  STARS = "shining3",
  CHEST_ATLAS = "chest_atlas",
  STARS_ATLAS = "stars_atlas",
  CHEST_2X_3X = "shining1_v2",
  CHEST_4X = "shining2",
  CHEST_5X = "shining3",
}

const VERSION = `v=1.0.0`;

export const atlasArray: AssetType[] = [
  {
    alias: AnimationsName.FANFARES_ATLAS,
    src: `/assets/animations/fanfares/fanfare.atlas?${VERSION}`,
  },
  {
    alias: AnimationsName.CHEST_ATLAS,
    src: `/assets/animations/chest/chest.atlas?${VERSION}`,
  },
  {
    alias: AnimationsName.STARS_ATLAS,
    src: `/assets/animations/stars/stars.atlas?${VERSION}`,
  },
];

export const skeletonArray: AssetType[] = [
  {
    alias: AnimationsName.FANFARES_SKELETON,
    src: `/assets/animations/fanfares/fanfare.json?${VERSION}`,
  },
  {
    alias: AnimationsName.CHEST_SKELETON,
    src: `/assets/animations/chest/chest.json?${VERSION}`,
  },
  {
    alias: AnimationsName.STARS_SKELETON,
    src: `/assets/animations/stars/stars.json?${VERSION}`,
  },
];

import React from "react";
import { ITextProps } from "./interface";

import styles from "./styles.module.scss";

const Text = ({ children, className }: ITextProps) => {
  return <span className={`${styles.text} ${className}`}>{children}</span>;
};

export default React.memo(Text);

import { ICONS } from "@/constants/icons";

export const isNotNeedPopUp = (name: string) => {
  return (
    name == ICONS.COIN ||
    name == ICONS.COIN_REVRESH ||
    name == ICONS.SCROLL ||
    name == ICONS.SCORLL_REVRESH
  );
};

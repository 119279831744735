import scroll from "@/assets/buttons/scroll.png";
import coin_revresh from "@/assets/buttons/coin_revresh.png";
import { IOptions } from "@/store/app/interfaces";
import upgrade from "@/assets/buttons/upgrade.png";

export const ACTIONS_WHARF:IOptions[] = [
  {
    id: 2,
    image: coin_revresh,
    directory: "auto_gathering",
    count: 34,
    height: 48,
    width: 48,
    position: {
      x: 0,
      y: 0,
    },
  },
  {
    id: 1,
    image: scroll,
    directory: "departure_ship",
    count: 38,
    height: 48,
    width: 48,
    position: {
      x: 0,
      y: 0,
    },
  },
  {
    id: 3,
    image: upgrade,
    count: 22,
    directory: "upgrade",
    height: 70,
    width: 48,
    position: {
      x: 0,
      y: 0,
    },
  },
];

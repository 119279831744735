import coin from "@/assets/buttons/coin.png";
import coin_revresh from "@/assets/buttons/coin_revresh.png";
import upgrade from "@/assets/buttons/upgrade.png";
import { IOptions } from "@/store/app/interfaces";

export const ACTIONS_BULDINGS:IOptions[] = [
  {
    id: 2,
    image: coin_revresh,
    directory: "auto_gathering",
    count: 35,
    height: 40,
    width: 48,
    position: {
      x: 0,
      y: 0,
    },
  },
  {
    id: 1,
    image: coin,
    count: 32,
    directory: "gathering_coin",
    width: 48,
    height: 96,
    position: {
      x: 0,
      y: 0,
    },
  },
  {
    id: 3,
    image: upgrade,
    count: 22,
    directory: "upgrade",
    height: 70,
    width: 48,
    position: {
      x: 0,
      y: -9,
    },
  },
];
import { useEffect } from "react";

const FacebookPixel = () => {
  useEffect(() => {
    if (typeof window !== "undefined") {
      if (!window.fbq) {
        (function (f, b, e, v, n, t, s) {
          if (f.fbq) return;
          n = f.fbq = function (...args) {
            if (n.callMethod) {
              n.callMethod.apply(n, args);
            } else {
              n.queue.push(args);
            }
          };
          if (!f._fbq) f._fbq = n;
          n.push = n.push || [];
          n.loaded = true;
          n.version = "2.0";
          n.queue = [];
          t = b.createElement(e);
          t.async = true;
          t.src = v;
          s = b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t, s);
        })(
          window,
          document,
          "script",
          "https://connect.facebook.net/en_US/fbevents.js"
        );

        window.fbq("init", "569645529116661");
        window.fbq("track", "PageView");
        window.fbq("track", "Lead");
      }
    }
  }, []);

  return (
    <noscript>
      <img
        height="1"
        width="1"
        style={{ display: "none" }}
        src="https://www.facebook.com/tr?id=569645529116661&ev=PageView&noscript=1"
        alt=""
      />
    </noscript>
  );
};

export default FacebookPixel;

import React, { useEffect } from "react";
import CanvasMenu from "@/components/Canvases/Menu";
import Layout from "@/components/Layout";
import HudButtons from "@/components/HudButtons";

import FacebookPixel from "@/components/FacebookPixel";

export default function Menu() {
  return (
    <Layout>
      <CanvasMenu />
      <HudButtons />
      <FacebookPixel />
    </Layout>
  );
}

import { RootState } from "@/store";
import { selectHouseByName } from "@/store/island/islandSlice";
import { HOUSES } from "@/store/island/island.mockData";
import React, { useEffect, useState } from "react";
import Image from "next/image";
import { generateStylesByCoordinates } from "@/utils/houses/generateStylesByCoordinates";
import { useOutsideClick } from "@/hooks/useOutsideClick";
import Fade from "@/components/Fade";
import Sprite from "@/components/Sprite";
import { IShipCardProps } from "./interface";
import { useDispatch, useSelector } from "react-redux";
import { SHIP_OTPIONS } from "./shipcard.constant";
import { setRef } from "@/store/tutorial/tutorialSlice";

import styles from "./styles.module.scss";

const ShipCard = ({ image, coordinates }: IShipCardProps) => {
  const [isSelected, setIsSelected] = useState(false);
  const dispatch = useDispatch();
  const [isAnimationTraveling, setIsAnimationTraveling] = useState(false);
  const house = useSelector((state: RootState) =>
    selectHouseByName(state, HOUSES[0].house_type_name)
  );
  const isTraveling =
    house?.time_remaining_to_full_resources !== undefined &&
    house.time_remaining_to_full_resources > 0;

  const directory = "ship";

  const containerRef = useOutsideClick(() => {
    if (isSelected) {
      setIsSelected(false);
    }
  });

  useEffect(() => {
    dispatch(setRef({ name: directory, ref: containerRef.current }));
  }, [containerRef]);

  const handleEndFade = () => {
    setIsAnimationTraveling(true);
  };

  const handleEndAnimation = () => {
    setIsAnimationTraveling(false);
  };

  const stylesInline = generateStylesByCoordinates(coordinates);

  return (
    <div style={stylesInline} ref={containerRef} className={styles.card}>
      <Fade trigger={isTraveling} onFadeComplete={handleEndFade}>
        {!isAnimationTraveling ? (
          <div className={styles.card__content}>
            <Image
              loading="lazy"
              className={styles.card__image}
              width={image.width / 8}
              quality={100}
              src={image}
              alt="The picture of ship"
            />
          </div>
        ) : (
          <Sprite options={SHIP_OTPIONS} onEnd={handleEndAnimation} />
        )}
      </Fade>
    </div>
  );
};

export default React.memo(ShipCard);

import React, { useMemo } from "react";
import { formatTime } from "@/utils/formatters/formatTime";
import { ITimeProps } from "./interfaces";
import { RootState } from "@/store";
import { useSelector } from "react-redux";
import { selectHouseByName } from "@/store/island/islandSlice";
import AnimatedButton from "@/components/Button";
import { HOUSES } from "@/store/island/island.mockData";
import { ACTIONS_BULDINGS } from "../Containers/ActionsHouseContainer/actions.constants";
import { AutoBoostersCounter } from "./AutoBoostersCounter";


import styles from "./styles.module.scss";

const Timer = ({ isSelected, name, handleCollectResources }: ITimeProps) => {
  const house = useSelector((state: RootState) =>
    selectHouseByName(state, name)
  );
  const isCanCollect = house?.is_can_collect;
  const formattedTime = useMemo(
    () => formatTime(house?.time_remaining_to_full_resources || 0),
    [house?.time_remaining_to_full_resources]
  );

  const superBoosterActive = house?.super_booster_active;
  const coin = ACTIONS_BULDINGS[1];

  const boosters = house?.quantity_auto_boosters || 0;

  if (!house) return null;

  const isSpecialHouse = house.house_type_name === HOUSES[0].house_type_name;

  return (
    <>
      {!isSelected &&
      house?.time_remaining_to_full_resources &&
      house?.time_remaining_to_full_resources > 0 ? (
        <div
          className={`${styles.timer__wrapper} ${
            boosters == 0 ? styles.timer__wrapper__disable_space : ""
          }`}
        >
          <span className={`${styles.timer}`}>{formattedTime}</span>
          <AutoBoostersCounter boosters={boosters} superBoosterActive={superBoosterActive}/>
        </div>
      ) : (
        <>
          {!isSelected &&
            isCanCollect &&
            house?.level > 0 &&
            !isSpecialHouse && (
              <div className={styles.wrapper}>
                <AnimatedButton
                  onClick={handleCollectResources}
                  key={coin.id}
                  options={coin}
                />
              </div>
            )}
          {!isSelected &&
            house?.level > 0 &&
            isSpecialHouse &&
            isCanCollect && (
              <div className={styles.wrapper}>
                <AnimatedButton
                  onClick={handleCollectResources}
                  key={coin.id}
                  options={coin}
                />
              </div>
            )}
        </>
      )}
    </>
  );
};

export default React.memo(Timer);

import { SHIP_IMAGE } from "@/constants/buildings";

const directory = "ship";

export const SHIP_OTPIONS = {
  id: 1,
  count: 49,
  image: SHIP_IMAGE,
  directory: directory,
  margin: "",
  width: 240,
  position:{
    x:0,
    y:0
  },
  height: 200,
  speed: 1.2,
};

import React, { useState, useEffect } from "react";
import { IFadeProps } from "./interfaces";

export default function Fade({
  children,
  trigger,
  duration = 100,
  onFadeComplete,
}: IFadeProps) {
  const [opacity, setOpacity] = useState(1);

  useEffect(() => {
    if (!trigger) return;

    setOpacity(0);

    const timer = setTimeout(() => {
      if (onFadeComplete) {
        onFadeComplete();
      }
      setOpacity(1);
    }, duration);

    return () => clearTimeout(timer);
  }, [trigger, duration, onFadeComplete]);

  return (
    <div
      style={{
        zIndex: 10,
        opacity,
        transition: `opacity ${duration}ms ease-in-out`,
        backgroundColor: "transparent",
      }}
    >
      {children}
    </div>
  );
}

export const generateStylesByCoordinates = (
  coordinates: { x: number; y: number },
  withDifference?: boolean
) => {
  if (withDifference) {
    const baseResolution = {
      width: 380, 
      height: 600,
    };
    if (baseResolution.height != window.innerHeight) {
      const diference = window.innerHeight / baseResolution.height;

      return {
        left: `${coordinates.x - 5 * diference}vw`,
        top: `${coordinates.y}vh`,
      };
    } else {
      return {
        left: `${coordinates.x}vw`,
        top: `${coordinates.y}vh`,
      };
    }
  }

  return {
    left: `${coordinates.x}vw`,
    top: `${coordinates.y}vh`,
  };
};
